import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import * as yup from 'yup';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import graphql from 'babel-plugin-relay/macro';
import {useMutation, useQuery} from 'relay-hooks/lib';
import {Formik, FormikValues} from 'formik';
import {FTextField} from '../FMaterial/FTextfield/FTextField';
import {EditTimesheetMutation} from '../../__generated__/EditTimesheetMutation.graphql';
import {Toast} from '../Toast';
import {FDropdown} from '../FMaterial/FDropdown/FDropdown';
import {EditTimesheetUsersQuery} from '../../__generated__/EditTimesheetUsersQuery.graphql';
import {Close} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '15px',
    },
    innerPaper: {
      maxWidth: '600px',
      minWidth: '28vw',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100vw',
      },
    },
    buttonSection: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      padding: theme.spacing(2),
      boxSizing: 'border-box',
    },
    buttonPadding: {
      padding: theme.spacing(1),
      maxWidth: '250px',
      flexBasis: '100px',
      flexGrow: 1,
    },
    deleteButton: {
      marginRight: '20%',
    },
  })
);

interface UserTimesheetLineInterface {
  id: string | null;
  userTimesheetId: string | null;
  position: number;
  description: string | null;
  actualHours: number | null;
}

interface TimesheetInterface {
  id: string;
  billableHours: number;
  description: string;
  name: string;
  startDate: string;
  endDate: string;
}

interface UserTimesheetInterface {
  id: string | null;
  totalActualHours: number | null;
  userId: string | null;
  lines: readonly UserTimesheetLineInterface[];
  timesheet: TimesheetInterface;
}

interface Props {
  close: () => void;
  cancel: () => void;
  userTimesheet: UserTimesheetInterface | null;
  open: boolean;
}

export function EditTimesheet({close, cancel, userTimesheet, open}: Props) {
  const classes = useStyles();

  const [selectedUser, setSelectedUser] = React.useState<string | null>(null);

  const [mutation, {loading}] = useMutation<EditTimesheetMutation>(
    graphql`
      mutation EditTimesheetMutation($input: SaveUserTimesheetInput!) {
        updateUserTimesheet(input: $input) {
          clientMutationId
        }
      }
    `
  );

  function handleOnClose() {
    close();
  }

  function handleCancel() {
    cancel();
  }

  const {props} = useQuery<EditTimesheetUsersQuery>(query);

  const {initialValues, lineValidation} = React.useMemo(() => {
    const validationShape: any = {};
    const values: any = {
      userId: userTimesheet?.userId || '',
    };

    userTimesheet?.lines.forEach((line, index) => {
      const position = index + 1;
      const fieldKey = `line${position}`;
      validationShape[fieldKey] = yup
        .number()
        .required('This field is required');
      values[fieldKey] = line.actualHours || 0;
    });

    return {
      initialValues: values,
      lineValidation: yup.object().shape(validationShape),
    };
  }, [userTimesheet]);

  return (
    <Formik<FormikValues>
      initialValues={initialValues}
      validationSchema={lineValidation}
      onSubmit={async (value) => {
        try {
          const response = await mutation({
            variables: {
              input: {
                timesheetId: userTimesheet?.timesheet.id || '',
                userTimesheet: {
                  timesheetId: userTimesheet?.timesheet.id || '',
                  id: userTimesheet?.id || '',
                  totalActualHours: userTimesheet?.totalActualHours,
                  userId: value.userId || '',
                  lines:
                    userTimesheet?.lines.map((line, index) => ({
                      id: line.id || '',
                      userTimesheetId: line.userTimesheetId,
                      actualHours: value[`line${index + 1}`],
                      description: line.description,
                      position: index + 1,
                    })) ?? [],
                },
              },
            },
          });
          if (response) {
            Toast('success', 'Successfully updated timesheet');
            handleOnClose();
          }
        } catch (error) {
          Toast('error', error.message);
        }
      }}
    >
      {({submitForm}) => {
        return (
          <Drawer open={open} onClose={handleOnClose} anchor="right">
            <AppBar
              position="sticky"
              style={{
                backgroundColor: 'white',
                borderBottom: '1px solid lightgrey',
              }}
            >
              <Toolbar
                style={{
                  paddingLeft: '12px',
                  paddingRight: '12px',
                }}
              >
                <Box
                  pb={2}
                  pt={2}
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box>
                    <Typography variant="h5">Edit Timesheet</Typography>
                    <Typography variant="h6">
                      {`(${userTimesheet?.timesheet.name})`}
                    </Typography>
                  </Box>
                  <Button
                    style={{
                      boxShadow: 'none',
                      backgroundColor: '#FFFFFF',
                      width: '40px',
                      height: '28px',
                      minWidth: '40px',
                    }}
                    onClick={() => {
                      handleCancel();
                    }}
                  >
                    <Close />
                  </Button>
                </Box>
              </Toolbar>
              <Box
                width="100%"
                display={'flex'}
                justifyContent="flex-end"
                px={2}
              >
                <FDropdown
                  field={{
                    name: 'userId',
                    value: selectedUser || userTimesheet?.userId || '',
                    onChange: (event: React.ChangeEvent<{value: unknown}>) => {
                      setSelectedUser(event.target.value as string);
                    },
                  }}
                  fullWidth
                  label="Select User"
                  data={props?.viewer?.users?.edges.map((user) => ({
                    id: user.node.id,
                    description: user.node.displayName,
                  }))}
                  style={{marginBottom: '24px'}}
                />
              </Box>
            </AppBar>
            <Box className={classes.innerPaper}>
              <Box width={'100%'}>
                <TableContainer
                  style={{
                    borderRadius: '4px',
                    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2)',
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Week</TableCell>
                        <TableCell>Actual Hours</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userTimesheet?.lines.map((line, index) => {
                        return (
                          <TableRow
                            key={`line_${index}_${line.id || line.position}`}
                          >
                            <TableCell>{line.description}</TableCell>
                            <TableCell>
                              <FTextField
                                fullWidth
                                type="number"
                                field={{name: `line${index + 1}`}}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box padding={'10px'}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    onClick={submitForm}
                  >
                    {loading ? (
                      <CircularProgress size={26} style={{color: 'white'}} />
                    ) : (
                      'Confirm'
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Drawer>
        );
      }}
    </Formik>
  );
}

const query = graphql`
  query EditTimesheetUsersQuery {
    viewer {
      users(first: 100) {
        edges {
          node {
            id
            displayName
          }
        }
      }
    }
  }
`;
