/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type EditTimesheetUsersQueryVariables = {};
export type EditTimesheetUsersQueryResponse = {
  readonly viewer: {
    readonly users: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly displayName: string;
        };
      }>;
    };
  };
};
export type EditTimesheetUsersQuery = {
  readonly response: EditTimesheetUsersQueryResponse;
  readonly variables: EditTimesheetUsersQueryVariables;
};

/*
query EditTimesheetUsersQuery {
  viewer {
    users(first: 100) {
      edges {
        node {
          id
          displayName
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'Viewer',
      kind: 'LinkedField',
      name: 'viewer',
      plural: false,
      selections: [
        {
          alias: null,
          args: [
            {
              kind: 'Literal',
              name: 'first',
              value: 100,
            },
          ],
          concreteType: 'UserConnection',
          kind: 'LinkedField',
          name: 'users',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'UserConnectionEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'User',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'id',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'displayName',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: 'users(first:100)',
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'EditTimesheetUsersQuery',
      selections: v0 /*: any*/,
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'EditTimesheetUsersQuery',
      selections: v0 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'EditTimesheetUsersQuery',
      operationKind: 'query',
      text:
        'query EditTimesheetUsersQuery {\n  viewer {\n    users(first: 100) {\n      edges {\n        node {\n          id\n          displayName\n        }\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'b8b5aa880934354ede9ddf9e4671ca41';
export default node;
