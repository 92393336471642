import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  FormikStep,
  FormikStepper,
} from '../../modules/FormikStepper/FormikStepper';
import graphql from 'babel-plugin-relay/macro';
import {useMutation, useQuery} from 'relay-hooks/lib';
import {CaptureTimesheetPageQuery} from '../../__generated__/CaptureTimesheetPageQuery.graphql';
import {FDropdown} from '../../modules/FMaterial/FDropdown/FDropdown';
import * as yup from 'yup';
import {CaptureTimesheetPageMutation} from '../../__generated__/CaptureTimesheetPageMutation.graphql';
import {FTextField} from '../../modules/FMaterial/FTextfield/FTextField';
import {useNavigate} from 'react-router';
import {useAuthContext} from '../../context/AuthContext';
import {Toast} from '../../modules/Toast';

const validationSchema = yup.object().shape({
  billingPeriod: yup.string().required('This field is required'),
  userId: yup.string().required('This field is required'),
});

const lineValidationSchema = yup.object().shape({
  lines: yup.array().of(
    yup.object().shape({
      actualHours: yup.number().required('This field is required'),
    })
  ),
});

const billingPeriodValidation = yup.object().shape({
  billingPeriod: yup.string().required('This field is required'),
});

interface UserTimesheetLineInterface {
  id: string | null;
  userTimesheetId: string | null;
  position: number;
  description: string | null;
  actualHours: number | null;
}

interface TimesheetInterface {
  id: string;
  billableHours: number;
  description: string;
  name: string;
  startDate: string;
  endDate: string;
}
interface UserTimesheetInterface {
  id: string | null;
  totalActualHours: number | null;
  userId: string | null;
  lines: readonly UserTimesheetLineInterface[];
  timesheet: TimesheetInterface;
}

export function CaptureTimesheetPage() {
  const navigate = useNavigate();
  const {auth} = useAuthContext();

  React.useEffect(() => {
    if (!auth.authenticated) {
      navigate('/login');
    }
  }, [navigate, auth.authenticated]);

  const {props} = useQuery<CaptureTimesheetPageQuery>(query);

  const [
    userTimesheet,
    setUserTimesheet,
  ] = React.useState<UserTimesheetInterface | null>(null);

  const [mutation] = useMutation<CaptureTimesheetPageMutation>(graphql`
    mutation CaptureTimesheetPageMutation($input: SaveUserTimesheetInput!) {
      addUserTimesheet(input: $input) {
        userTimesheet {
          id
          totalActualHours
          userId
          lines {
            id
            userTimesheetId
            position
            description
            actualHours
          }
          timesheet {
            id
            billableHours
            description
            name
            startDate
            endDate
          }
        }
      }
    }
  `);

  if (!props) {
    return <></>;
  }

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      pl={5}
    >
      <Card style={{width: '100%', boxShadow: 'none'}}>
        <CardContent>
          <FormikStepper
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              userId: '',
              billingPeriod: '',
              lines:
                userTimesheet?.lines.map((line) => ({
                  id: line.id,
                  userTimesheetId: line.userTimesheetId,
                  actualHours: line.actualHours || 0,
                  description: line.description,
                })) || [],
            }}
            onSubmit={(value) => {
              mutation({
                variables: {
                  input: {
                    timesheetId: userTimesheet?.timesheet.id || '',
                    userTimesheet: {
                      timesheetId: userTimesheet?.timesheet.id || '',
                      id: userTimesheet?.id,
                      totalActualHours: userTimesheet?.totalActualHours,
                      userId: userTimesheet?.userId,
                      lines:
                        userTimesheet?.lines.map((line, index) => ({
                          id: line.id || '',
                          userTimesheetId: line.userTimesheetId,
                          actualHours: value[`line${index + 1}`],
                          description: line.description,
                          position: index + 1,
                        })) ?? [],
                    },
                  },
                },
                onError: (error) => {
                  Toast('error', error.message);
                },
                onCompleted: () => {
                  Toast('success', 'Successfully created timesheet');
                  navigate('/timesheets');
                },
              });
            }}
          >
            <FormikStep
              label="Select employee and billing period"
              toastMessage="Please choose the user and billing period"
              validationSchema={billingPeriodValidation}
              handleFunction={async (value) => {
                const response = await mutation({
                  variables: {
                    input: {
                      timesheetId: value.billingPeriod,
                      userTimesheet: {
                        userId: value.userId,
                        timesheetId: userTimesheet?.timesheet.id || '',
                        totalActualHours: userTimesheet?.totalActualHours,
                        lines:
                          userTimesheet?.lines.map((line, index) => ({
                            id: line.id || '',
                            userTimesheetId: line.userTimesheetId,
                            actualHours: value.lines[index]?.actualHours,
                            description: line.description,
                            position: index + 1,
                          })) ?? [],
                      },
                    },
                  },
                });

                if (response) {
                  setUserTimesheet(response.addUserTimesheet.userTimesheet);
                }
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box width="600px" mr={5}>
                  <Box
                    px={2}
                    pb={2}
                    style={{boxSizing: 'border-box'}}
                    width="100%"
                  >
                    <FDropdown
                      field={{name: 'userId'}}
                      fullWidth
                      label="Select User"
                      data={props.viewer.users.edges.map((user) => ({
                        id: user.node.id,
                        description: user.node.displayName,
                      }))}
                      style={{marginBottom: '24px'}}
                    />
                    <FDropdown
                      field={{name: 'billingPeriod'}}
                      fullWidth
                      label={'Select the billing period'}
                      data={props?.viewer.lastXMonthsTimesheetPeriods.map(
                        (period) => ({
                          id: period.id,
                          description: period.description,
                        })
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </FormikStep>

            <FormikStep
              label="Capture Actual Hours"
              validationSchema={lineValidationSchema}
              handleFunction={() => {}}
              toastMessage="Please capture the actual hours you worked"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box width="800px" mr={5}>
                  <TableContainer
                    style={{
                      borderRadius: '4px',
                      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2)',
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Week</TableCell>
                          <TableCell>Actual Hours</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userTimesheet &&
                        userTimesheet?.lines &&
                        userTimesheet.lines.length > 0 ? (
                          userTimesheet.lines.map((line, index) => (
                            <TableRow
                              key={`line_${index}_${line.id || line.position}`}
                            >
                              <TableCell>{line.description}</TableCell>
                              <TableCell>
                                <FTextField
                                  fullWidth={true}
                                  type="number"
                                  field={{name: `line${index + 1}`}}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              No lines available for this timesheet.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </FormikStep>
          </FormikStepper>
        </CardContent>
      </Card>
    </Box>
  );
}

const query = graphql`
  query CaptureTimesheetPageQuery {
    viewer {
      users(first: 100) {
        edges {
          node {
            id
            displayName
          }
        }
      }
      lastXMonthsTimesheetPeriods(number: 3) {
        id
        startDate
        endDate
        billableHours
        name
        description
      }
    }
  }
`;
