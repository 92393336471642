/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type CaptureTimesheetPageQueryVariables = {};
export type CaptureTimesheetPageQueryResponse = {
  readonly viewer: {
    readonly users: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly displayName: string;
        };
      }>;
    };
    readonly lastXMonthsTimesheetPeriods: ReadonlyArray<{
      readonly id: string;
      readonly startDate: string;
      readonly endDate: string;
      readonly billableHours: number;
      readonly name: string;
      readonly description: string;
    }>;
  };
};
export type CaptureTimesheetPageQuery = {
  readonly response: CaptureTimesheetPageQueryResponse;
  readonly variables: CaptureTimesheetPageQueryVariables;
};

/*
query CaptureTimesheetPageQuery {
  viewer {
    users(first: 100) {
      edges {
        node {
          id
          displayName
        }
      }
    }
    lastXMonthsTimesheetPeriods(number: 3) {
      id
      startDate
      endDate
      billableHours
      name
      description
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = [
      {
        alias: null,
        args: null,
        concreteType: 'Viewer',
        kind: 'LinkedField',
        name: 'viewer',
        plural: false,
        selections: [
          {
            alias: null,
            args: [
              {
                kind: 'Literal',
                name: 'first',
                value: 100,
              },
            ],
            concreteType: 'UserConnection',
            kind: 'LinkedField',
            name: 'users',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'UserConnectionEdge',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: 'User',
                    kind: 'LinkedField',
                    name: 'node',
                    plural: false,
                    selections: [
                      v0 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'displayName',
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: 'users(first:100)',
          },
          {
            alias: null,
            args: [
              {
                kind: 'Literal',
                name: 'number',
                value: 3,
              },
            ],
            concreteType: 'Timesheet',
            kind: 'LinkedField',
            name: 'lastXMonthsTimesheetPeriods',
            plural: true,
            selections: [
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'startDate',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'endDate',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'billableHours',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'name',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'description',
                storageKey: null,
              },
            ],
            storageKey: 'lastXMonthsTimesheetPeriods(number:3)',
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'CaptureTimesheetPageQuery',
      selections: v1 /*: any*/,
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'CaptureTimesheetPageQuery',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'CaptureTimesheetPageQuery',
      operationKind: 'query',
      text:
        'query CaptureTimesheetPageQuery {\n  viewer {\n    users(first: 100) {\n      edges {\n        node {\n          id\n          displayName\n        }\n      }\n    }\n    lastXMonthsTimesheetPeriods(number: 3) {\n      id\n      startDate\n      endDate\n      billableHours\n      name\n      description\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'd4600c54cecfd91684889477c6c6b8e0';
export default node;
